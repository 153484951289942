<template>
    <div>
        <!-- Form Modal -->
        <b-modal
            id="modal-fileUplaod-form"
            ref="uploadingModal"
            centered
            no-close-on-backdrop
            no-close-on-esc
            size="xs"
            @ok="uploadConsignment"
        >
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <h5>Consignment Upload</h5>
                
                <feather-icon
                    class="ml-4 pr-0 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="close()"
                />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button
                    size="sm"
                    variant="success"
                    @click="ok()"
                    :disabled="uploading"
                >
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="uploading" />
                    <span v-if="!uploading">Upload</span>
                    <span v-if="uploading">Uploading...</span>
                </b-button>
                <b-button
                    size="sm"
                    @click="cancel()"
                    variant="outline-secondary"
                >
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{}">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">
                        <b-row>
                            <b-col cols="12" md="12">
                                <b-form-group labe-for="season_id" label="Season">
                                    <validation-provider #default="{ errors }" name="Season" rules="">
                                        <b-form-select
                                            size="sm"
                                            v-model="file.season_id"
                                            id="season_id"
                                            name="season_id"
                                            :state="errors.length > 0 ? false : null"
                                        >
                                            <b-form-select-option v-for="element in seasons" :key="element.id" :value="element.id">{{ element.value }}</b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.season_id">{{ serverErrors.season_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" md="12">
                                <b-form-group labe-for="file_id" label="Content Type">
                                    <validation-provider #default="{ errors }" name="Content Type" rules="">
                                        <b-form-select
                                            size="sm"
                                            v-model="file.file_id"
                                            id="file_id"
                                            name="file_id"
                                            :state="errors.length > 0 ? false : null"
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="myFile in files" :key="myFile.id" :value="myFile.id">{{ myFile.description }} ({{ myFile.type }})</b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.file_id">{{ serverErrors.file_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" md="12">
                                <input ref="fileInput" type="file" @change="convertFile" />
                            </b-col>
                        </b-row>

                        <!-- <b-card>
                            {{ file }}
                        </b-card> -->
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Form Modal -->
        <b-modal
            id="modal-season-form"
            ref="myModal"
            centered
            no-close-on-backdrop
            no-close-on-esc
            @ok="submit"
        >
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="consignment.id === null">Register Consignment</h5>
                <h5 v-if="consignment.id !== null">Edit Consignment: {{ consignment.number }}</h5>
                
                <feather-icon
                    class="ml-4 pr-0 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="close()"
                />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button
                    size="sm"
                    variant="primary"
                    @click="ok()"
                    :disabled="saving"
                >
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button
                    size="sm"
                    @click="cancel()"
                    variant="outline-secondary"
                >
                    Cancel
                </b-button>
            </template>

                <!-- Data Form in Modal Body -->
                <template #default="{}">
                    <validation-observer ref="dataForm" #default="{ invalid }">
                        <b-form ref="form" @submit.stop.prevent="handleCreate">
                            <b-row>
                                <b-col cols="12" md="4">
                                    <b-form-group label-for="number" label="Consignment #">
                                        <validation-provider #default="{ errors }" name="Consignment #" rules="">
                                            <b-form-input
                                                id="number"
                                                name="number"
                                                v-model="consignment.number"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.number">{{ serverErrors.number[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="4">
                                    <b-form-group label-for="cartons" label="Cartons">
                                        <validation-provider #default="{ errors }" name="Cartons" rules="">
                                            <b-form-input
                                                id="cartons"
                                                name="cartons"
                                                v-model="consignment.cartons"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                                disabled
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.cartons">{{ serverErrors.cartons[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="4">
                                    <b-form-group label-for="standardTare" label="Standard Tare">
                                        <validation-provider #default="{ errors }" name="Standard Tare" rules="">
                                            <b-form-input
                                                id="standardTare"
                                                name="standardTare"
                                                v-model="consignment.standardTare"
                                                :state="errors.length > 0 ? false : null"
                                                type="number"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.standardTare">{{ serverErrors.standardTare[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" md="4">
                                    <b-form-group label-for="tare" label="Tare">
                                        <validation-provider #default="{ errors }" name="Tare" rules="">
                                            <b-form-input
                                                id="tare"
                                                name="tare"
                                                v-model="consignment.tare"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                                disabled
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.tare">{{ serverErrors.tare[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="4">
                                    <b-form-group label-for="net" label="Net">
                                        <validation-provider #default="{ errors }" name="Net" rules="">
                                            <b-form-input
                                                id="net"
                                                name="net"
                                                v-model="consignment.net"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                                disabled
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.net">{{ serverErrors.net[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="4">
                                    <b-form-group label-for="gross" label="Gross">
                                        <validation-provider #default="{ errors }" name="Gross" rules="">
                                            <b-form-input
                                                id="gross"
                                                name="gross"
                                                v-model="consignment.gross"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                                disabled
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.gross">{{ serverErrors.gross[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" md="8">
                                    <b-form-group label-for="truck" label="Truck Number">
                                        <validation-provider #default="{ errors }" name="Truck Number" rules="">
                                            <b-form-input
                                                id="truck"
                                                name="truck"
                                                v-model="consignment.truck.number"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.truck">{{ serverErrors.truck[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" md="8">
                                    <b-form-group label-for="container" label="Container Number">
                                        <validation-provider #default="{ errors }" name="Container Number" rules="">
                                            <b-form-input
                                                id="container"
                                                name="container"
                                                v-model="consignment.container.number"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.container">{{ serverErrors.container[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="4">
                                    <b-form-group label-for="type" label="Type">
                                        <validation-provider #default="{ errors }" name="Type" rules="">
                                            <b-form-input
                                                id="type"
                                                name="type"
                                                v-model="consignment.container.type"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.type">{{ serverErrors.type[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" md="4">
                                    <b-form-group label-for="size" label="Size">
                                        <validation-provider #default="{ errors }" name="Size" rules="">
                                            <b-form-input
                                                id="size"
                                                name="size"
                                                v-model="consignment.container.size"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.size">{{ serverErrors.size[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="4">
                                    <b-form-group label-for="seal" label="Seal">
                                        <validation-provider #default="{ errors }" name="Seal" rules="">
                                            <b-form-input
                                                id="seal"
                                                name="seal"
                                                v-model="consignment.seal"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.seal">{{ serverErrors.seal[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="4">
                                    <b-form-group label-for="customSeal" label="Custom Seal">
                                        <validation-provider #default="{ errors }" name="Custom Seal" rules="">
                                            <b-form-input
                                                id="customSeal"
                                                name="customSeal"
                                                v-model="consignment.customSeal"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.customSeal">{{ serverErrors.customSeal[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-card>
                                <b-row>
                                    <b-col cols="12" class="justify-content-end">
                                        <div class="d-flex align-items-center justify-content-end">
                                            <b-button
                                                size="sm"
                                                variant="outline-success"
                                                @click="addRow()"
                                            >
                                                <feather-icon
                                                    icon="PlusIcon"
                                                    class="mr-50"
                                                />
                                                <span>Add Row</span>
                                            </b-button>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <table width="100%">
                                                <thead>
                                                    <tr>
                                                        <th width="10%">#</th>
                                                        <th width="40%">Start</th>
                                                        <th width="40%">End</th>
                                                        <th width="10%"></th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div style="height:15vh;overflow-y:auto;border:1px solid #eee;">
                                            <table width="100%" striped>
                                                <tbody>
                                                    <tr v-for="(container, index) in consignment.numbering">
                                                        <td width="10%">{{ index + 1 }}</td>
                                                        <td width="40%">
                                                            <b-form-input
                                                                v-model="consignment.numbering[index].start"
                                                                size="sm"
                                                            />
                                                        </td>
                                                        <td width="40%">
                                                            <b-form-input
                                                                v-model="consignment.numbering[index].end"
                                                                size="sm"
                                                            />
                                                        </td>
                                                        <td width="10%" align="center">
                                                            <span
                                                                style="cursor:pointer;"
                                                                :title="'Remove from' + container.start + ' to ' + container.end"
                                                                @click="removeRow(index)"
                                                            >
                                                                <feather-icon icon="Trash2Icon" />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-form>
                    </validation-observer>
                </template>
            </b-modal>
            <!-- ./Form Modal -->

        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="6" md="2">
                        <label>Season</label>
                        <b-form-select
                            size="sm"
                            v-model="file.season_id"
                        >
                            <b-form-select-option
                                v-for="season in seasons"
                                :key="season.id"
                                :value="season.id"
                            >
                                {{ season.value }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-row>
            </b-card-body>
            <!-- {{ file }} -->
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="sm"
                            />
                            <b-button
                                variant="primary"
                                size="sm"
                                @click="invokeUplaodForm()"
                            >
                                <b-spinner small class="mr-1" label="Small Spinner" v-if="uploading" />
                                <span class="text-nowrap" v-if="!uploading">Upload Consignment</span>
                                <span class="text-nowrap" v-if="uploading">Uploading...</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                
                <template #cell(container)="data">
                    <span v-if="data.item.container === null">{{ data.item.number }}</span>
                    <span v-if="data.item.container !== null">{{ data.item.container.number }}</span>
                </template>
                <template #cell(shipment)="data">
                    <span v-if="data.item.shipment.internalNumber">{{ data.item.shipment.internalNumber }}</span>
                    <span v-else>{{ data.item.shipment.number }}</span>
                </template>
                <template #cell(transactionDate)="data">
                    {{ data.item.transactionDate | moment('DD/MM/YYYY') }}
                </template>
                <template #cell(cartons)="data">
                    {{ data.item.cartons.toLocaleString() }}
                </template>
                <template #cell(tare)="data">
                    {{ data.item.tare.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                </template>
                <template #cell(net)="data">
                    {{ data.item.net.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                </template>
                <template #cell(gross)="data">
                    {{ data.item.gross.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
        
                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormTextarea,
        BSpinner,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import { ref, onUnmounted, onMounted } from '@vue/composition-api'
    import consignmentsStoreModule from '@/views/cromis/shipping/consignments/consignmentsStoreModule'
    import useConsignmentsList from '@/views/cromis/shipping/consignments/useConsignmentsList'
    import moment from 'moment'
    import { useToast } from 'vue-toastification/composition'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    export default {
        props: {},
        components: {
            BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
            BForm, BFormGroup,
            BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormTextarea,
            BSpinner,
            ValidationObserver, ValidationProvider,
        },
        directives: {},

        setup(props, context) {
            const toast = useToast()
            const serverErrors = ref({})
            const saving = ref(false)
            const uploading = ref(false)
            const dataForm = ref(null)
            const myModal = ref(null)
            const uploadingModal = ref(null)
            const fileInput = ref(null)

            const seasons = ref([{ id: null, value: 'Select...' }])
            const files = ref([])

            const file = ref({
                season_id: null,
                file_id: null,
                document: null,
                type: null,
                size: 0,
                unit: 'KB',
                originalName: null,
                season_id: null,
                contents: 'consignment',
            })

            const consignment = ref({})
            
            const CROMIS_STORE_MODULE_NAME = 'cromis-consignment'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, consignmentsStoreModule)

            // UnRegister on leave
            onUnmounted(async () => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                await store.dispatch('cromis-consignment/seasons')
                        .then(response => {
                            let apiSeasons = response.data.seasons
                            apiSeasons.map((item) => {
                                seasons.value.push({ id: item.id, value: `${ item.startYear }/${ item.endYear }` })
                            })
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })

                await store.dispatch('cromis-consignment/files')
                        .then(response => {
                            files.value = response.data.files
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })
            })

            const {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,
            } = useConsignmentsList()

            const isFormValid = async () => {
                let isValid = false 
                await dataForm.value.validate().then(success => {
                    isValid = success
                })

                return isValid
            }

            const submit = (bvModalEvt) => {
                bvModalEvt.preventDefault()

                serverErrors.value = null
                // Handle form submit
                if (consignment.value.id === null || consignment.value.id === 0)
                    handleCreate()
                else
                    handleUpdate(consignment.value)
            }

            const invokeUpdateForm = (item) => {
                serverErrors.value = null
                saving.value = false

                consignment.value = {
                    id: item.id,
                    number: item.number,
                    cartons: item.cartons.toLocaleString(),
                    standardTare: (item.tare / item.cartons).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    tare: item.tare.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    gross: item.gross.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    net: item.net.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    truck: item.truck? item.truck : { id: null, number: null},
                    container: item.container,
                    seal: item.seal,
                    customSeal: item.customSeal,
                    numbering: item.numbering
                }

                myModal.value.show()
            }

            const invokeCreateForm = () => {
                serverErrors.value = null
                saving.value = false

                port.value = {
                    id: null,
                    name: null,
                    country_id: null,
                }

                myModal.value.show()
            }

            const invokeUplaodForm = () => {
                serverErrors.value = null
                uploading.value = false

                file.value.document = null
                file.value.type = null
                file.value.size = 0
                file.value.unit = 'KB'
                file.value.originalName = null
                file.value.season_id = null

                uploadingModal.value.show()
            }

            const openFileBrowser = () => {
                fileInput.value.click()
            }

            const convertFile = (e) => {
                file.value.document = null
                file.value.type = null
                file.value.size = 0
                file.value.unit = 'KB'
                file.value.originalName = null
                file.value.contents = 'consignment'

                let rawFile = e.target.files[0]

                file.value.originalName = rawFile.name
                file.value.size = rawFile.size / 1048576 < 1 ?
                                        rawFile.size / 1024 : rawFile.size / 1048576

                file.value.unit = rawFile.size / 1048576 < 1 ? 'KB' : 'MB'

                file.value.type = rawFile.type

                serverErrors.value = null
                uploading.value = false

                let name = file.value.originalName
                /*
                if(!['pdf'].includes(name.substring(name.indexOf('.') + 1))){
                    serverErrors.value = { fileInput: [
                        'Invalid file type, required pdf file'
                    ] }
                }
                */

                let reader = new FileReader()

                reader.onloadend = async () => {
                    file.value.document = await reader.result.substring(reader.result.indexOf(',') + 1)
                }

                reader.readAsDataURL(rawFile)
            }

            const uploadConsignment = async () => {
                uploading.value = true

                await store.dispatch('cromis-consignment/imports', file.value)
                                .then(response => {
                                    refetch()
                                    uploading.value = false

                                    file.value.document = null
                                    file.value.type = null
                                    file.value.size = 0
                                    file.value.unit = 'KB'
                                    file.value.originalName = null
                                    file.value.contents = 'consignment'
                                    
                                    toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: response.data.message,
                                            icon: 'CoffeeIcon',
                                            variant: 'success',
                                        },
                                    })
                                })
                                .catch(error => {
                                    uploading.value = false
                                    console.log(error)
                                    context.root.$swal({
                                        icon: 'error',
                                        title: `${ error.response.status } ${ error.response.statusText }`,
                                        text: 'Something went wrong. See tech support!',
                                        showConfirmButton: true,
                                        customClass: {
                                            confirmButton: 'btn btn-outline-danger',
                                        },
                                        buttonsStyling: false,
                                    })
                                })
            }

            const handleCreate = async () => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                
                await store.dispatch('cromis-consignment/create', port.value)
                    .then(response => {
                        refetch()
                        saving.value = false

                        myModal.value.hide()

                        context.root.$swal({
                            icon: 'success',
                            text: `Port registered successfully!`,
                            showConfirmButton: true,
                            timer: 3000,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        saving.value = false
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const handleUpdate = async (item) => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                await store.dispatch('cromis-consignment/update', { id: item.id, data: item })
                    .then(response => {
                        refetch()
                        saving.value = false

                        myModal.value.hide()

                        context.root.$swal({
                            icon: 'success',
                            text: `Changes to port ${response.data.name} has been saved successfully!`,
                            showConfirmButton: true,
                            timer: 2000,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        saving.value = false
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const remove = async (id) => {
                await store.dispatch('cromis-consignment/remove', id)
                            .then(response => {
                                refetch()
                            })
                            .catch(error => {
                                context.root.$swal({
                                    icon: 'error',
                                    title: 'Server Error',
                                    text: 'Something went wrong. See tech support',
                                    showConfirmButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: true,
                                })
                            })
            }

            const removeRow = (index) => {
                consignment.value.numbering.splice(index, 1)
            }

            return {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                dataForm,
                myModal,
                uploadingModal,
                fileInput,
                file,
                files,
                consignment,
                seasons,

                refetch,
                invokeCreateForm,
                invokeUpdateForm,
                openFileBrowser,
                invokeUplaodForm,
                uploadConsignment,
                convertFile,
                isFormValid,
                submit,
                serverErrors,
                saving,
                uploading,
                handleCreate,
                handleUpdate,
                remove,
                removeRow,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>